import React,{useEffect,useState} from "react";
import $ from 'jquery';
import * as app from "../fetch/fetchApi";


const FormularioSitioInteres = () => {
  const isBrowser = typeof window !== "undefined"

  const [alerta, setAlerta] = useState('');

  function ValidateEmail(mail) 
  {
   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }
  if (isBrowser) {

  $("formulario").submit(function (event) {
   /* var formData = {
      name: $("#name").val(),
      email: $("#email").val(),
      superheroAlias: $("#superheroAlias").val(),
    };

    $.ajax({
      type: "POST",
      url: "process.php",
      data: formData,
      dataType: "json",
      encode: true,
    }).done(function (data) {
      console.log(data);
    });*/
    console.log('pruebas')

    event.preventDefault();
  });
  const sendData =(event)=>{
    console.log('pruebas')

    event.preventDefault();
  }
  document.addEventListener("DOMContentLoaded", function(event) {

  var form = document.getElementById("formulario");

// attach event listener
form.addEventListener("submit", sendData, true);
  })
  function postData(url = '', data = {}) {
    // Opciones por defecto estan marcadas con un *
    const response =  fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
   //return console.log('ok')
  }
  
 setTimeout(() => {
   
 
  $("#formularioContacto").unbind('submit').on("submit",function(event){ 
    var flag;
    console.log(ValidateEmail($("#email").val()))
    console.log($("#telefono").val())
    console.log($("#nombreCompleto").val())
    console.log($("#email").val())
    console.log($("#comentario").val())
    if($("#nombreCompleto").val()==''||
    $("#telefono").val()==''||
    $("#email").val()==''||
    $("#comentario").val()==''){
    flag=false;
}else{
  flag= true;
}

if (flag==false||ValidateEmail($("#email").val())==false) {
  console.log('1')
  setAlerta('error')
  setTimeout(() => {
     setAlerta('')
  }, 3000);
}else if (flag==true&&ValidateEmail($("#email").val())==true){
  var formData = {
    nombreCompleto: $("#nombreCompleto").val(),
    telefono: $("#telefono").val(),
    email: $("#email").val(),
    comentario: $("#comentario").val(),

  };
  fetch(app.url+'/node-api/m22_insert_formulario_sitio/', {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(formData, null, 4), // data can be `string` or {object}!
    headers:{
      'Content-Type': 'application/json'
    }
  }).then(res => res.json())
  .catch(error => {
     console.error('Error:', error)
     setAlerta('error')
     setTimeout(() => {
       setAlerta('')
    }, 3000);
 })
  .then(response => {
     console.log('Success:', response) 
    setAlerta('success')
    setTimeout(() => {
       setAlerta('')
    }, 3000);
 });
 $('#formularioContacto')[0].reset();
event.preventDefault()
}
  
})
}, 2000);
  }
  return (
   <div className={'bloque mt-5'} style={{padding:'28px'}}>
     <div>
     <h2 className={'text-center tituloFormulario'}>¡Nos encanta que quieras escribirnos! </h2>
     <p className="labelFormulario">Este espacio es para leer todas tus preguntas, dudas y sugerencias. Vamos a darle seguimiento puntual porque lo más valioso es que tú tengas la mejor experiencia.</p>
     </div>  
       <div>
       <form className={'row'} action="javascript:void(0);" id={'formularioContacto'}  method={'post'}>
         <div className={'col-12'}>
         <input type={'text'} id={'nombreCompleto'} className={'inputFormularioSitio'} placeholder={'Nombre completo'} />
         </div>
         <div className={'col-md-6 col-sm-12'}>
         <input type={'text'} id={'telefono'}  pattern="[1-9]{1}[0-9]{9}" maxLength="10" minLength="10"  className={'inputFormularioSitio'} placeholder={'Telefono'} />
         </div>
         <div className={'col-md-6 col-sm-12'}>
         <input type={'text'} id={'email'}  className={'inputFormularioSitio'} placeholder={'Correo electrónico'} />
         </div>
         <div className={'col-12'}>
         <textarea  id={'comentario'} name="w3review" rows="10" cols="50"  className={'inputFormularioSitioArea'} placeholder={'Escribe tu mensaje'}>
  
  </textarea>
         </div>
         <div className={'d-flex justify-content-center'}>
         <button  class="secondary boton-azul button radius form-submit col-lg-2 mt-lg-2 mb-lg-3 col-md-2 mt-md-2 mb-md-3 col-4 mb-4 mt-2"  id="enviarFormulario" name="enviarFormulario" value="Buscar" type="submit" style={{width:'93px'}}>Enviar</button>

         </div>
         { alerta=='success'? <div id="add-to-cart-toast-message" style={{visibility:'visible'}} class="paragraph-sb">Formulario enviado correctamente</div>:''}
  {alerta=='error'?<div id="toast-message" class=""><span class="paragraph-sb"><strong>Error:</strong> ingresar los datos correctamente</span></div>:''}
  
       </form>
       </div>
   </div>
  );
};

export default FormularioSitioInteres;