import React from "react";


  
const ImagenContacto = (data) => {
  let uri =data.data.field_imagen_inicial_sitio.und[0].uri;
  uri = uri.replace('public://', '')
  return (
    
<div className={'mobile position-relative'} style={{width:'100%'}}>
  {console.log(data.data.field_imagen_inicial_sitio.und[0].uri)}
 <img  src={'../../static/contacto.jpg'} height="440" class="img-fluid mobile" style={{width:'100%'}}/>
</div>
  );
};

export default ImagenContacto;